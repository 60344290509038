
import './App.css';
import Section1 from './comp/Section1/Section1';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import Section2 from './comp/Section2/Section2';
import Section3 from './comp/Section3/Section3';
import Section4 from './comp/Section4/Section4';

function App() {
  return (
    <div className="App">
     <BrowserRouter>
  <Routes>
  <Route path="/" element={<Section1 />} />
  <Route path="/project-details" element={<Section2 />} />
  <Route path="/vesting-details" element={<Section3 />} />
  <Route path="/saved" element={<Section4 />} />
        </Routes>
    </BrowserRouter>

    </div>
  );
}

export default App;
