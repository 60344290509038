import React from 'react'
import "./Section1.css"
export default function Section1() {
  return (<div className='section1-body'>

        <div className='section1-header'>
        <div className='wallet'>
            <a href=''>
            Connect wallet

            </a>
        </div>
        </div>
        <div className='section1'>

        <div  className='section1-left'>
           <div  className='section1-left-title'>
           Create New <br></br>
Vesting Agreement

           </div>
           <div  className='section1-left-text'>
           Easy way to create Smart Vesting 
Contracts for your investors.
           </div>
           <div  className='section1-left-btn'>
               <a href='/project-details'>
               get started

               </a>
           </div>
           <div>
               <img className='arrow' src='./asset/Vector 5.png' alt=''/>
           </div>
           <div  className='section1-left-bottom'>
           Already Vested? <br></br>
           <a href=''>
           Check out Dashboard

           </a>
           </div>

       </div>
       <div  className='section1-right'>
       <img className='ring' src='./asset/Glossy.png' alt=''/>
       </div>

        </div>

  </div>
    
  )
}
