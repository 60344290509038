import React, { useState } from 'react'
import "./Section2.css"
import "./Sass.scss"
export default function Section2() {
    const [token, setToken] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
  return (<div className='section1-body'>
 <div className='section1-header'>
        <div className='connect-wallet '>    
            <div class="tabs">

<div class="tab">
  <input className='wallet-btn' type="checkbox" id="chck2"/>
  <label style={{marginTop:'0'}} class="tab-label" for="chck2">Connected....Wallet_Address
</label>
  <div class="tab-content">
  Connected <br></br>
  0x5460DC4250CAFOTcF780cBIcOC91D<br></br>
  <span style={{color:'#FB4949'}}>Change wallet</span>

  </div>
</div>
</div>
        </div>
        </div>
        <div className='center-section2'>


<div className='section2'>
    <div className='section2-title'>
    Fill your project details
    </div>
    <div>
    <label>Token Address*
       
    <input 
      type="text" 
      value={token}
      onChange={(e) => setToken(e.target.value)}
    />
  </label>
    </div>

    <div>
    <label>Project Name*
    <input 
      type="text" 
      value={name}
      onChange={(e) => setName(e.target.value)}
    />
  </label>
    </div>

    <div>
    <label>Project Description*
    <textarea 
      type="text" 
      value={description}
      onChange={(e) => setDescription(e.target.value)}
    />
  </label>
    </div>
    <div  className='form-btn'>
    <div className=''>
        < a href='/vesting-details'>
        next

        </a>
    </div>
    </div>


 
</div>    </div>
        </div>
   
  )
}
