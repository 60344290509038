import React, { useState } from 'react'
import "./Section3.css"

export default function Section3() {
  const [cliff, setCliff] = useState("");
  const [vesting1, setVesting1] = useState("");
  const [address1, setAddress1] = useState("");
  const [amount, setAmount] = useState("");
  const [vesting2, setVesting2] = useState("");
  const [value, onChange] = useState(new Date());
  return (<div className='section1-body'>
  <div className='section1-header'>
        <div className='connect-wallet'>
        <div class="tab">
  <input className='wallet-btn' type="checkbox" id="chck2"/>
  <label style={{marginTop:'0'}} class="tab-label" for="chck2">Connected....Wallet_Address
</label>
  <div class="tab-content">
  Connected <br></br>
  0x5460DC4250CAFOTcF780cBIcOC91D<br></br>
  <span style={{color:'#FB4949'}}>Change wallet</span>

  </div>
</div>
        </div>
        </div>
    <div className='last-page-center'>


    <div className='section3'>
      <div className='section3-title-header'>
      <div className='section3-title'>
        Enter Vesting Details
        </div>
        <div className='upload'>
        <div>
      <span>
      <img className='export' src='./asset/export.svg' alt=''/>&nbsp;Upload sheet
        </span> 
        </div>
        </div>

      </div>
      <div  className='section3-row-fields'>

       
        <div>
        <label>Cliff Period*
           
        <input 
          className='input2'
          type="date" 
          value={cliff}
          onChange={(e) => setCliff(e.target.value)}
        />
  
      </label>
        </div>

        <div>
        <label>Vesting Period*
        <input 
          className='input2'
          type="text" 
          value={vesting1}
          onChange={(e) => setVesting1(e.target.value)}
        />
      </label>
        </div>

        <div>
        <label>Vesting Period*
        <input 
        className='input2'
          type="text" 
          value={vesting2}
          onChange={(e) => setVesting2(e.target.value)}
        />
      </label>
        </div>
       
    

     
    </div>    
    <div   className='section3-row-fields'>
    <div>
        <label>Address 1:
           
        <input 
          type="text" 
          className='input2'
          value={address1}
          onChange={(e) => setAddress1(e.target.value)}
        />
      </label>
        </div>
        <div>
        <label>Amount*
           
        <input 
          type="text" 
          value={amount}
          className='input2'
          onChange={(e) => setAmount(e.target.value)}
        />
      </label>
        </div>
    </div>
   
    <div  className='form-btn'>
    <div className='add-another'>
    + Add another
        </div>
        <div className=''>
          <a style={{color:'black',textDecoration:"none"}} href='/saved'>
          Save
          </a>
        </div>
        </div>
    
    
    </div>    
    </div>
  </div>)
}
