import React from 'react'
import "./Section4.css"
export default function Section4() {
  return (<div className='section1-body'>
  <div className='section1-header'>
        <div className='connect-wallet'>
        <div class="tab">
  <input className='wallet-btn' type="checkbox" id="chck2"/>
  <label style={{marginTop:'0'}} class="tab-label" for="chck2">Connected....Wallet_Address
</label>
  <div class="tab-content">
  Connected <br></br>
  0x5460DC4250CAFOTcF780cBIcOC91D<br></br>
  <span style={{color:'#FB4949'}}>Change wallet</span>

  </div>
</div>
        </div>
        </div>
    <div className='last-page-center'>
   

    <div className='section4'>
        {
            [1,2,3].map(()=>
            <>
              <div  className='section4-row'>

     <div className='section4-row-fields'>
       
<div>
<div> Token Name</div>
<div>
FITMINT
</div>
</div>

<div>
<div> Token Remaining</div>
<div>
5000
</div>
</div>

<div>
<div> Token Name</div>
<div>
20:23
</div>
</div>


<div  className='form-btn display'>
<div className='claim-btn'>
< a style={{color:'black',textDecoration:"none"}} href='/'>
  Claim
  </a>
</div>
</div>



</div> 
<div  className='form-btn display2'>
<div className='claim-btn'>
< a style={{color:'black',textDecoration:"none"}} href='/'>
  Claim
  </a>
</div>
</div>


</div>  
            </>)
        }
     
      
  
   
 
    
    
    </div>    
    </div>
  </div>)
}
